function ToolbarExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
}

ToolbarExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
ToolbarExtension.prototype.constructor = ToolbarExtension;

ToolbarExtension.prototype.load = function () {
  return true;
};

ToolbarExtension.prototype.unload = function () {
  this.viewer.toolbar.removeControl(this.subToolbar);
  return true;
};

ToolbarExtension.prototype.onToolbarCreated = function (toolbar) {
  let viewer = this.viewer;

  // Button 1
  let button1 = new Autodesk.Viewing.UI.Button('show-env-bg-button');
  button1.onClick = function (e) {
    viewer.setGhosting(false);
  };
  button1.addClass('hide-ghosting-button');
  button1.setToolTip('Hide Ghosting');
  button1.icon.classList.add('mdi', 'mdi-ghost-off-outline');

  // Button 2
  let button2 = new Autodesk.Viewing.UI.Button('hide-env-bg-button');
  button2.onClick = function (e) {
    viewer.setGhosting(true);
  };
  button2.addClass('show-ghosting-button');
  button2.setToolTip('Show Ghosting');
  button2.icon.classList.add('mdi', 'mdi-ghost-outline');

  // SubToolbar
  this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('my-custom-toolbar');
  this.subToolbar.addControl(button1);
  this.subToolbar.addControl(button2);

  toolbar.addControl(this.subToolbar);
};

Autodesk.Viewing.theExtensionManager.registerExtension(
  'ToolbarExtension',
  ToolbarExtension
);
